/* BOSTON COLLEGE COLORS */

/* See https://www.bc.edu/bc-web/styles/bc-web-style-guide/color.html */

/* BC Brand colors */
$bc-brand-maroon: #8a100b;
$bc-brand-gold: #b29d6c;
$bc-brand-gray: #726158;

/* Primary palette */
$bc-primary-dark-red: #501315;
$bc-primary-light-gold: #dccaa0;

/* Secondary palette */
$bc-secondary-light-sage: #c5cfac;
$bc-secondary-bright-sage: #dde392;

/* LOCAL COLORS */
$pitch_black: #000;

$very-dark-gray: #101010;
$dark-gray: #313131;
$light-gray: #b7b7b7;
$very-light-gray: #dadada;
$just-barely-gray: #eaeaea;

$red: #990000;
$dark-blue-green: #05506D;

/* Text colors */
$white-text: #fff;
$black-text: $pitch_black;
$wcagaaa-compliant-gray-text: #595959;
$wcagaa-compliant-gray-text: #757575;

/* Link colors */
$bc-link-color: #2f7ab9;
$wcgaa-link-against-very-light-gray: saturate(darken($bc-link-color, 9%), 9%);

/* Body colors (borders, backgrounds, etc) */
$white-body: #fff;
$black-body: $pitch_black;
