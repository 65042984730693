@charset "utf-8";

body {
    font-family: 'Open Sans', Arial, sans-serif;
    color: $black-text;
    font-size: 15px;
    line-height: 1.75;
}

.accessibility-text {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.alert-hours {
    margin-bottom:1rem;
    padding: 1rem;
}

/* links */
a {
    color: #2f7ab9; /* BC link color */
}

#facpub h2 a {
    font-size: 24px;
    border-bottom: 1px solid;
    text-decoration: none;
}

#ds-highlight .content h3,
#digcol-highlight .content h3 {
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: 200;
    line-height: 1.25;
}

#ds-highlight .content h3 a,
#digcol-highlight .content h3 a {
    color: $white-text;
    border-bottom: 1px solid $white-body;
}

#ds-highlight .content h3 a:hover,
#ds-highlight .content h3 a:focus,
#digcol-highlight .content h3 a:hover,
#digcol-highlight .content h3 a:focus {
    color: $bc-primary-light-gold;
    border-bottom: 1px solid $bc-primary-light-gold;
    text-decoration: none;
}


/*  boostrap overrides  */
.row {
    padding-top: 1em;
    padding-bottom: 1em;
}

@media (min-width: 768px) {
    .row.equal {
        display: flex;
        flex-wrap: wrap;
    }
}

/* top level maroon bar */
#bclib-header {
    background: $bc-brand-maroon no-repeat 100% 0;
    border-bottom: 1px solid $bc-primary-light-gold;
    height: 38px;
    display: flex;
    align-items: center;
}

#skiplink {
    position: absolute;
    top: -1000px;
    left: -1000px;
    height: 1px;
    width: 1px;
    text-align: left;
    overflow: hidden;
}

#skiplink:active,
#skiplink:focus,
#skiplink:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    overflow: visible;
    background-color: $white-body;
    padding: 4px;
}

#bclib-header a {
    color: $white-text;

    &:hover, &a:focus {
        color: $bc-primary-light-gold;
        text-decoration: none;
    }
}

#bclib-header .row {
    padding-top: 0;
    padding-bottom: 0;
}

#bclib-header ul.bclib-header-links {
    text-align: right;
    margin: 0;
    text-transform: uppercase;
    /* we want height = 30px */
    /*    15 * 2      = 30   */
    /*    14 * 2.143 ~= 30   */
    /*    13 * 2.308 ~= 30   */
    /*    12 * 2.5    = 30   */
    font-size: 14px;
    line-height: 2.143;
}

#bclib-header ul.bclib-header-links li {
    display: inline;
    margin: 0 0 0 20px;

    .fa {
        font-size: 14px;
    }
}

/* end top level maroon bar */


.megamenu-container {
    margin: 0;
    padding: 0;
}

/* content sections */

/* header */
#hero-box {
    padding-bottom: 4rem;
    padding-top: 80px;
    background-color: #467282;
}

#header-top-row {
    padding-top: 0;
}

.caption-link {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px 10px;
    background-color: rgba(16, 14, 15, .85);
    color: $white-text;
    font-size: 12px;
    font-weight: 200;
}

.caption-link a {
    color: $white-text;
}

.caption-link a:hover,
.caption-link a:focus {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .navbar-nav {
        float: right;
        margin: 0;
    }
}

/* Search panel */
#search-panel {
    background-color: $bc-brand-maroon;
    border-radius: 6px;
}

#search-panel .fa-search {
    padding-right: .5em;
}

#search-panel #search-links {
    padding-top: 0
}

#search-panel #search-links a, #search-panel #search-links a:visited {
    color: white;
    font-size: 18px;
}

#search-panel input.form-control, #search-panel button.btn.btn-default {
    height: 50px;
    font-size: 20px;
}

.online-only-label {
    color: #f3f3f3;
}

span.online-only-label-text {
    margin-left: .25em;
    margin-right: .25em;
}

#online-only-checkbox {
    margin-top: 6px;
}

label.checkbox-inline.online-only-label {
    font-style: italic;
    font-size: 16px;
}

button.whats-this, button.whats-this:active, button.whats-this:hover {
    text-decoration: none;
    border: none;
    cursor: help;
}

.whats-this-message {
    padding: .25em .5em;
    display: none;
    font-size: .9em;
    color: white;
}

.container-fluid .pull-right {
    height: auto;
}

.leftBottom,
.leftTop,
.rightTop,
.rightBottom {
    position: relative;
}

.leftBottom .container-fluid .pull-right,
.leftTop .container-fluid .pull-right,
.rightTop .container-fluid .pull-right,
.rightBottom .container-fluid .pull-right {
    position: absolute;
}

.leftTop .container-fluid .pull-right {
    left: 0;
    top: 20px;
}

.rightTop .container-fluid .pull-right {
    top: 20px;
    right: 0;
}

.leftBottom .container-fluid .pull-right {
    left: 0;
    bottom: 20px;
}

.rightBottom .container-fluid .pull-right {
    bottom: 20px;
    right: 0;
}

.leftBottom .container-fluid .pull-right .content,
.leftTop .container-fluid .pull-right .content,
.rightTop .container-fluid .pull-right .content,
.rightBottom .container-fluid .pull-right .content {
    color: $white-text;
    display: table;
    padding: 40px 40px;
    max-width: 60%;
    background: rgba(0, 0, 0, 0.5);
}

.rightTop .container-fluid .pull-right .content,
.rightBottom .container-fluid .pull-right .content {
    float: right;
}

.megamenu-container .libraryh3lp > a.chat-link {
    font-weight: 600;
}

/* Ask A Librian box styles */
.aal-box,
.lib-box {
    background-color: #f8f8f8;
    font-size: 16px;
}

.lib-box .panel-body .media a.lib-thumb {
    display: block;
}

.aal-box p:last-child,
.lib-box p:last-child {
    margin-bottom: 0;
}

.aal-box .fa {
    margin-left: 2px;
    font-weight: 600;
}

.aal-box h3,
.aal-box .panel h3,
.lib-box h3,
.lib-box .panel h3 {
    font-size: larger;
    font-weight: 600;
}

.aal-box a {
    /* darken to better contrast against light-gray background */
    color: #3076AB;
}

.aal-box a.aal-icon {
    font-size: 100px !important;
    color: $white-text;
    display: block;
    vertical-align: top;
    line-height: 1;
    width: 141px;
}

.aal-box a.aal-icon i {
    padding: 20px;
    width: 100%;
}

.aal-box .chat-icon-link.aal-icon, .aal-box #chat-icon-link.aal-icon {
    background-color: $bc-brand-gold;
}

.aal-box .email-icon-link.aal-icon, .aal-box #email-icon-link.aal-icon {
    background-color: #a25729;
}

.aal-box .meet-icon-link.aal-icon, .aal-box #meet-icon-link.aal-icon  {
    background-color: #204375;
}

.aal-box #visit-icon-link.aal-icon {
    background-color: $bc-primary-light-gold;
}

.aal-box #faq-icon-link.aal-icon {
    background-color: #07625d;
}

.aal-box .aal-icon:focus,
.aal-box .aal-icon:active,
.aal-box .aal-icon:hover {
    background-color: #31b0d5 !important;
}

.lib-box .panel-body .media img {
    max-width: 141px !important;
}

/* Ask A Librarian small boxes */
.lib-box-small .panel-body .media img {
    max-width: 100px !important;
}

.aal-box-small,
.lib-box-small {
    font-size: inherit;
}

.aal-box-small .panel-body,
.lib-box-small .panel-body {
    padding: 10px 5px 10px 10px;
}

.aal-box-small a.aal-icon {
    font-size: 60px !important;
    width: 100px;
}

.aal-box-small h3,
.aal-box-small .panel h3,
.lib-box-small h3,
.lib-box-small .panel h3 {
    font-size: 15px;
}

#lib-footer .alert-hours {
    padding: .5rem 1rem;
    font-weight: 400;
    margin-bottom: .5rem;
}

.alert-hours a, .alert-hours a:hover, #lib-footer .alert-hours a, #lib-footer .alert-hours a:hover {
    color: darkslateblue;
    font-weight: 700;
}

@media ( max-width: 1199px ) {

    #bcl-feedback #feedback_wrapper {
        width: 90%;
        display: inline-block;
        position: absolute;
        top: 5%;
        left: 0;
        right: 0;
        transform: translate(0px, 0%);
        margin: 40px auto 20px auto;
    }
}


/* media query overrides */

@media screen and (min-width: 1200px) {
    #quick-links .quick-link-block {
        height: 128px;
    }
}

/* Landscape tablets and all desktops */
@media screen and (min-width: 992px) {
    #bclib-header .input-wrapper {
        padding-right: 0;
    }
}

/* Landscape tablets and medium desktops */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #quick-links .quick-link-block {
        height: 184px;
    }
}

/* Portrait tablets and small desktops */
@media (max-width: 991px) {
    #bclib-header .col-sm-12 {
        width: 100%;
    }

    #bclib-header ul.bclib-header-links {
        text-align: right;
    }

    #bclib-header ul.bclib-header-links li:first-child {
        margin-left: 0;
    }

    #bclib-header ul.bclib-header-links li:last-child {
        margin-right: 0;
    }

    #bclib-header .header-right {
        padding-left: 0;
        padding-right: 0;
    }

    #news {
        padding-bottom: 0;
    }

    #facpub img.bookcover {
        display: none !important;
    }

    #news .more-news {
        text-align: center;
        margin-top: -1em;
    }

    .navbar-brand {
        display: none;
    }
}

@media (min-width: 768px) {
    #hero-box {
        padding-top: 80px;
        height: 320px;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    #bclib-header > .container {
        width: 100%;
    }

    #bclib-header ul.bclib-header-links {
        font-size: 15px;
        line-height: 2;
    }

    #bclib-header ul.bclib-header-links li {
        margin: 0 10px;
    }

    .container-fluid .pull-right {
        left: auto !important;
        top: auto !important;
        bottom: 0 !important;
        right: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        transform: none !important;
        position: absolute;
    }

    .container-fluid .pull-right .content {
        padding: 15px 15px !important;
        max-width: 100% !important;
        width: 100%;
        text-align: center;
        position: relative;
        bottom: auto;
        right: auto;
        left: auto;
    }

    #scholarship .row {
        margin-left: 1em;
        margin-right: 1em;
    }

    #search-panel button.btn.btn-default .search-button-text {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    .caption-link {
        display: none;
    }

    #search-panel #search-links div {
        display: inline-block;
    }
}

/* Specific to the bc-lib header */
@media (max-width: 600px) {
    #bclib-header .link-wrapper,
    #bclib-header .input-wrapper {
        width: 100%;
    }

    #bclib-header .input-wrapper .pull-right {
        float: none !important;
        padding: 4px 0 6px 0;
    }
}

@media (max-width: 480px) {
    .col-xxs {
        display: block;
        float: none;
        width: 100%;
        margin: 0;
    }

    #bclib-header {
        height: auto;
    }

    #bclib-header .container {
        width: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    #bclib-header ul.bclib-header-links {
        text-align: left;
        width: 100%;
    }
}

/* Ask A Librarian specific */
@media (max-width: 424px) {
    .aal-box .panel-body .media img,
    .aal-box-small .panel-body .media img,
    .lib-box .panel-body .media img,
    .lib-box-small .panel-body .media img {
        max-width: 90px !important;
    }

    .aal-box a.aal-icon,
    .aal-box-small a.aal-icon {
        width: 90px !important;
        font-size: 50px !important;
    }
}

/* Specific to the bc-lib header */
@media (max-width: 400px) {
    #bclib-header .link-wrapper .fa-commenting-o,
    #bclib-header .link-wrapper .fa-user {
        display: none;
    }
}

/* Ask A Librarian specific */
@media (max-width: 374px) {
    .aal-box a.aal-icon,
    .aal-box-small a.aal-icon,
    .aal-box .panel-body .media img,
    .aal-box-small .panel-body .media img,
    .lib-box .panel-body .media img,
    .lib-box-small .panel-body .media img {
        display: none;
    }
}

@media print {
    body {
        font-size: large;
    }

    body:before {
        content: "Boston College Libraries " !important;
        font-size: 2em;
        font-weight: normal;
    }

    a:after {
        content:" (" attr(href) ") " !important;
        font-size: 0.8em;
        font-weight: normal;
        word-wrap: break-word;
    }

    p {
        page-break-inside: avoid;
    }

    .view-mode .s-lib-box .s-lib-box-title {
        font-size: large;
    }

    #midlevelcontainer-refresh {
        margin-top: 0 !important;
    }

    #bclib-header,
    .megamenu-container,
    iframe,
    a[name=s-lg-guide-main],
    #s-lg-guide-tabs,
    #s-lib-scroll-top,
    #s-lib-footer-login-link {
        display: none !important;
    }

    .s-lg-guide-body [class*="col-"] {
        float: left;
        width: 100%;
    }

    /*  homepage only styles */
    #ds-highlight h3,
    #ds-highlight h3 a,
    #exhibit-highlight h2,
    #exhibit-highlight h2 a,
    #scholarship h2,
    #scholarship h2 a,
    #exhibit-highlight h2,
    #exhibit-highlight h2 a,
    #facpub h2,
    #facpub h2 a,
    #digcol-highlight h3,
    #digcol-highlight h3 a {
        font-size: 22px !important;
        font-weight: normal !important;
        letter-spacing: 1px !important;
        color: $black-text !important;
        text-decoration: none !important;
        margin: 0 0 1em 0 !important;
        padding: 0 !important;
        border: 0 !important;
    }

    .mainhomepage .container,
    .mainhomepage .container-fluid {
        width: 100% !important;
    }

    .mainhomepage #hero-box,
    .mainhomepage #storybox {
        display: none;
    }

    #news {
        display: none;
    }

    #quick-links {
        border-top: 1px solid $very-light-gray;
        border-bottom: 1px solid $very-light-gray;
        padding: 1em 0;
    }

    #quick-links .quick-link-block {
        text-align: left;
        padding: .25em 0 !important;
    }

    #ds-highlight {
        height: auto !important;
        padding: 0 !important;
        text-align: left !important;
    }

    #ds-highlight [class*="col-"] {
        position: relative !important;
        text-align: left !important;
    }

    #ds-highlight .content {
        padding: 0;
    }

    .mainhomepage .embed-responsive {
        display: none;
    }

    #scholarship {
        text-align: left !important;
    }

    #exhibit-highlight .carousel-control,
    #exhibit-highlight img.exhibit-poster,
    #exhibit-highlight .carousel-indicators,
    #exhibit-highlight .exhibit-right {
        display: none;
    }

    #exhibit-highlight .exhibit-box {
        padding: 0 !important;
        margin: 0 !important;
        height: auto !important;
    }

    #exhibit-highlight .exhibit-box .btn {
        padding: 0;
    }

    #exhibit-highlight .carousel {
        margin-bottom: 0 !important;
    }

    #exhibit-highlight .col-md-offset-2 {
        margin-left: 0 !important;
    }

    #facpub {
        text-align: left !important;
    }

    #facpub .more-link {
        text-align: left !important;
    }

    #digcol-highlight {
        height: auto !important;
        padding: 0 !important;
        border-top: 1px solid $very-light-gray;
        border-bottom: 1px solid $very-light-gray;
        text-align: left !important;
    }

    #digcol-highlight [class*="col-"] {
        position: relative !important;
    }

    #digcol-highlight .content {
        padding: 1.5em 0 0 0;
    }
}
